import React from 'react'
import styled from 'styled-components'
import { ProductGridData } from '../../content/Content'
import { useAppState } from '../../GlobalState'
import { Heading2 } from '../atoms/Typography'

const Container = styled.div`
  width: 672px;
  max-width: 100%;
  margin: auto;
  padding: 0 0 32px;
  display: grid;
  align-items: flex-end;

  justify-items: center;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  row-gap: 16px;

  @media (max-width: 920px) {
    padding: 0 0 64px;
  }
`

const DetailImage = styled.img`
  display: block;
  width: 320px;
  max-width: 100%;
  height: auto;
  opacity: 75%;
`

const ProductLabel = styled.div<{ uppercase?: boolean }>`
  font-family: DINNextW01Medium, sans-serif;
  text-transform: ${p => (p.uppercase ? 'uppercase' : 'none')};
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1px;
  margin: 18px 0 8px;
`

const ProductYear = styled(Heading2)`
  font-family: DINNextW01Regular, sans-serif;
  font-size: 14px;
  line-height: 14px;
  margin: 0;
`

const Product = styled.div<{ alone?: boolean; alignStart?: boolean }>`
  max-width: 328px;
  margin: 16px 0;
  cursor: pointer;
  grid-column-end: ${p => (p.alone ? 'span 2' : 'span 1')};
  align-self: ${p => (p.alignStart ? 'start' : 'end')};
  @media (max-width: 920px) {
    grid-column-end: span 2;
  }
  &:hover {
    ${DetailImage} {
      opacity: 100%;
    }
    ${ProductLabel} {
      text-decoration: underline;
    }
  }
`

const Divider = styled.div`
  grid-column-end: span 2;
  color: #484848;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.92px;
  margin: 0 0 -16px;
`
const DividerLine = styled.div`
  width: 2px;
  height: 32px;
  border-left: 2px solid #484848;
  margin: 0 auto 13px;
`

interface ProductGridProps {
  data: ProductGridData[]
}
export const ProductGrid = (props: ProductGridProps): JSX.Element => {
  const { actions } = useAppState()
  const onClick = (contentId: string) => {
    actions.openPopup(contentId)
  }
  return (
    <Container>
      {props.data.map(product => {
        if (product.type === 'gun') {
          return (
            <Product key={product.label} onClick={() => onClick(product.id)} alone={product.alone}>
              <DetailImage src={product.image} />
              <ProductLabel uppercase>{product.label}</ProductLabel>
              {product.year && <ProductYear uppercase>{product.year}</ProductYear>}
            </Product>
          )
        }
        if (product.type === 'video' || product.type === 'hotspot') {
          return (
            <Product key={product.label} alignStart onClick={() => onClick(product.id)} alone={false}>
              <DetailImage src={product.image} />
              <ProductLabel uppercase>{product.label}</ProductLabel>
            </Product>
          )
        }
        if (product.type === 'divider') {
          return (
            <Divider>
              <DividerLine />
              {product.id}
            </Divider>
          )
        }
      })}
    </Container>
  )
}
