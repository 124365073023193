import { ContentId } from '../Constants'
import { ProductButton } from './Content'
import s20Precision from './images/s20-precision.png'
import s20Hunting from './images/s20-hunting.png'
import sako85Finnlight2 from './images/sako85-finnlight2.png'
import sako85Finnlight2Bg from './images/sako85-finnlight2-bg.png'
import sako85Carbonwolf from './images/sako85-carbonwolf.png'
import sako85CarbonwolfBg from './images/sako85-carbonwolf-bg.png'
import sako85Safari from './images/sako85-safari.png'
import sako85SafariBg from './images/sako85-safari-bg.png'
import sako85Bavarian from './images/sako85-bavarian.png'
import sako85BavarianBg from './images/sako85-bavarian-bg.png'
import sako85BlackBear from './images/sako85-blackbear.png'
import sako85BlackBearBg from './images/sako85-blackbear-bg.png'
import sako85Carbonlight from './images/sako85-carbonlight.png'
import sako85CarbonlightBg from './images/sako85-carbonlight-bg.png'
import sako85Grizzly from './images/sako85-grizzly.png'
import sako85GrizzlyBg from './images/sako85-grizzly-bg.png'
import sako85SyntheticBlack from './images/sako85-syntheticblack.png'
import sako85SyntheticBlackBg from './images/sako85-syntheticblack-bg.png'
import historicLotta from './images/historic-lotta.png'
import historicLottaBg from './images/historic-lotta-bg.png'
import historicSuomi from './images/historic-suomi.png'
import historicSuomiBg from './images/historic-suomi-bg.png'
import historicPystykorvaM28 from './images/historic-pystykorva-m28.png'
import historicPystykorvaM28Bg from './images/historic-pystykorva-m28-bg.png'
import historicPystykorvaM2830 from './images/historic-pystykorva-m2830.png'
import historicPystykorvaM2830Bg from './images/historic-pystykorva-m2830-bg.png'
import historicUkkopekka from './images/historic-ukkopekka.png'
import historicUkkopekkaBg from './images/historic-ukkopekka-bg.png'
import historicL46 from './images/historic-l46.png'
import historicL46Bg from './images/historic-l46-bg.png'
import historicL57 from './images/historic-l57.png'
import historicL57Bg from './images/historic-l57-bg.png'
import historicVixen from './images/historic-vixen.png'
import historicVixenBg from './images/historic-vixen-bg.png'
import historicFinnbear from './images/historic-finnbear.png'
import historicFinnbearBg from './images/historic-finnbear-bg.png'
import historicRK62 from './images/historic-rk62.png'
import historicRK62Bg from './images/historic-rk62-bg.png'
import historicFinnwolf from './images/historic-finnwolf.png'
import historicFinnwolfBg from './images/historic-finnwolf-bg.png'
import historicTikkaM07 from './images/historic-m07.png'
import historicTikkaM07Bg from './images/historic-m07-bg.png'
import historicTikkaM55 from './images/historic-m55.png'
import historicTikkaM55Bg from './images/historic-m55-bg.png'
import historicTikkaM65 from './images/historic-m65.png'
import historicTikkaM65Bg from './images/historic-m65-bg.png'
import historicTRG21 from './images/historic-trg21.png'
import historicTRG21Bg from './images/historic-trg21-bg.png'
import historicTikkaM558 from './images/historic-m558.png'
import historicTikkaM558Bg from './images/historic-m558-bg.png'
import historicTriace from './images/historic-triace.png'
import historicTriaceBg from './images/historic-triace-bg.png'
import historicRK95 from './images/historic-rk95.png'
import historicRK95Bg from './images/historic-rk95-bg.png'
import historic75 from './images/historic-75.png'
import historic75Bg from './images/historic-75-bg.png'
import historicTRG42 from './images/historic-trg42.png'
import historicTRG42Bg from './images/historic-trg42-bg.png'
import historicTikka512S from './images/historic-512s.png'
import historicTikka512SBg from './images/historic-512s-bg.png'
import historicTikkaT3 from './images/historic-t3.png'
import historicTikkaT3Bg from './images/historic-t3-bg.png'
import historicTikkaT3Tactical from './images/historic-t3-tactical.png'
import historicTikkaT3TacticalBg from './images/historic-t3-tactical-bg.png'
import historicQuad from './images/historic-quad.png'
import historicQuadBg from './images/historic-quad-bg.png'
import historicSako85 from './images/historic-85.png'
import historicSako85Bg from './images/historic-85-bg.png'
import historicSakoA7 from './images/historic-a7.png'
import historicSakoA7Bg from './images/historic-a7-bg.png'
import historicSakoTRGM10 from './images/historic-trg-m10.png'
import historicSakoTRGM10Bg from './images/historic-trg-m10-bg.png'
import historicTikkaT3x from './images/historic-t3x.png'
import historicTikkaT3xBg from './images/historic-t3x-bg.png'
import historicT3xTactA1 from './images/historic-t3x-tact-a1.png'
import historicT3xTactA1Bg from './images/historic-t3x-tact-a1-bg.png'
import historicC19Ranger from './images/historic-c19.png'
import historicC19RangerBg from './images/historic-c19-bg.png'
import historicT1x from './images/historic-t1x.png'
import historicT1xBg from './images/historic-t1x-bg.png'
import historicSakoS20 from './images/historic-s20.png'
import historicSakoS20Bg from './images/historic-s20-bg.png'
import historicSako100Bg from './images/historic-sako100-bg.png'
import historicSako90Bg from './images/historic-sako90-bg.png'
import trg42 from './images/trg-42.png'
import trg42Bg from './images/trg-42-bg.png'
import trgM10 from './images/trg-m10.png'
import trgM10Bg from './images/trg-m10-bg.png'
import tikkaT1xMTR from './images/tikka-t1x-mtr.png'
import tikkaT1xMTRBg from './images/tikka-t1x-mtr-bg.png'
import tikkaT3xCompactTactical from './images/tikka-t3x-compact-tactical.png'
import tikkaT3xCompactTacticalBg from './images/tikka-t3x-compact-tactical-bg.png'
import tikkaT3xHunter from './images/tikka-t3x-hunter.png'
import tikkaT3xHunterBg from './images/tikka-t3x-hunter-bg.png'
import tikkaT3xLitePolyfade from './images/tikka-t3x-lite-polyfade.png'
import tikkaT3xLitePolyfadeBg from './images/tikka-t3x-lite-polyfade-bg.png'
import tikkaT3xLiteRoughtech from './images/tikka-t3x-roughtech.png'
import tikkaT3xLiteRoughtechBg from './images/tikka-t3x-roughtech-bg.png'
import tikkaT3xTactA1 from './images/tikka-t3x-tact-a1.png'
import tikkaT3xTactA1Bg from './images/tikka-t3x-tact-a1-bg.png'
import tikkaT3xUPR from './images/tikka-t3x-upr.png'
import tikkaT3xUPRBg from './images/tikka-t3x-upr-bg.png'
import tikkaT3xLiteVeilAlpine from './images/tikka-t3x-veil-alpine.png'
import tikkaT3xLiteVeilAlpineBg from './images/tikka-t3x-veil-alpine-bg.png'
import sako100Wood from './images/sako100-explorer-wood.png'
import sako100WoodBg from './images/sako100-explorer-wood-bg.png'
import sako100Carbon from './images/sako100-explorer-carbon.png'
import sako100CarbonBg from './images/sako100-explorer-carbon-bg.png'
import sako90Peak from './images/sako90-peak.png'
import sako90PeakBg from './images/sako90-peak-bg.png'
import sako90QuestUltra from './images/sako90-quest-ultra.png'
import sako90QuestUltraBg from './images/sako90-quest-ultra-bg.png'
import sako90Quest from './images/sako90-quest.png'
import sako90QuestBg from './images/sako90-quest-bg.png'
import sako90Hunter from './images/sako90-hunter.png'
import sako90HunterBg from './images/sako90-hunter-bg.png'
import sako90Adventure from './images/sako90-adventure.png'
import sako90AdventureBg from './images/sako90-adventure-bg.png'
import sako90Bavarian from './images/sako90-bavarian.png'
import sako90BavarianBg from './images/sako90-bavarian-bg.png'
import sako90Varmint from './images/sako90-varmint.png'
import sako90VarmintBg from './images/sako90-varmint-bg.png'

export enum GunId {
  SakoS20Precision = 'sakos20-precision',
  SakoS20Hunting = 'sakos20-hunting',
  Sako85Finnlight2 = 'sako85-finnlight2',
  Sako85Carbonwolf = 'sako85-carbonwolf',
  Sako85Safari = 'sako85-safari',
  Sako85Bavarian = 'sako85-bavarian',
  Sako85BlackBear = 'sako85-blackbear',
  Sako85Carbonlight = 'sako85-carbonlight',
  Sako85Grizzly = 'sako85-grizzly',
  Sako85SyntheticBlack = 'sako85-syntheticblack',
  HistoricLotta = 'historic-lotta',
  HistoricSuomi = 'historic-suomi',
  HistoricPystykorvaM28 = 'historic-pystykorva-m28',
  HistoricPystykorvaM2830 = 'historic-pystykorva-m28/30',
  HistoricUkkopekka = 'historic-ukkopekka',
  HistoricL46 = 'historic-l46',
  HistoricL57 = 'historic-l57',
  HistoricVixen = 'historic-vixen',
  HistoricFinnbear = 'historic-finnbear',
  HistoricRK62 = 'historic-rk62',
  HistoricFinnwolf = 'historic-finnwolf',
  HistoricTikkaM07 = 'historic-m07',
  HistoricTikkaM55 = 'historic-m55',
  HistoricTikkaM65 = 'historic-m65',
  HistoricTRG21 = 'historic-trg21',
  HistoricTikkaM558 = 'historic-m558',
  HistoricTriace = 'historic-triace',
  HistoricRK95 = 'historic-rk95',
  Historic75 = 'historic-75',
  HistoricTRG42 = 'historic-trg42',
  HistoricTikka512S = 'historic-512s',
  HistoricTikkaT3 = 'historic-t3',
  HistoricTikkaT3Tactical = 'historic-t3-tactical',
  HistoricSakoQuad = 'historic-quad',
  HistoricSako85 = 'historic-85',
  HistoricSakoA7 = 'historic-a7',
  HistoricSakoTRGM10 = 'historic-trg-m10',
  HistoricTikkaT3x = 'historic-t3x',
  HistoricTikkaT3xTactA1Military = 'historic-t3x-tact-a1-military',
  HistoricTikkaT3xTactA1Civilian = 'historic-t3x-tact-a1-civilian',
  HistoricTikkaC19 = 'historic-c19-ranger',
  HistoricTikkaT1x = 'historic-t1x',
  HistoricSakoS20 = 'historic-s20',
  HistoricSako90 = 'historic-90',
  HistoricSako100 = 'historic-100',
  TikkaT1xMTR = 'tikka-t1x-mtr',
  TikkaT3xCompactTactical = 'tikka-t3x-compact-tactical',
  TikkaT3xHunter = 'tikka-t3x-hunter',
  TikkaT3xLitePolyfade = 'tikka-t3x-lite-polyfade',
  TikkaT3xLiteRoughtech = 'tikka-t3x-lite-roughtech',
  TikkaT3xTactA1 = 'tikka-t3x-tact-a1',
  TikkaT3xUPR = 'tikka-t3x-upr',
  TikkaT3xLiteVeilAlpine = 'tikka-t3x-lite-veil-alpine',
  SakoTRG42 = 'sakotrg-42',
  SakoTRGM10 = 'sakotrg-m10',
  Sako100Wood = 'sako100-wood',
  Sako100Carbon = 'sako100-carbon',
  Sako90Peak = 'sako90-peak',
  Sako90QuestUltra = 'sako90-quest-ultra',
  Sako90Quest = 'sako90-quest',
  Sako90Hunter = 'sako90-hunter',
  Sako90Adventure = 'sako90-adventure',
  Sako90Bavarian = 'sako90-bavarian',
  Sako90Varmint = 'sako90-varmint',
}

export enum GunFamily {
  S20 = 's20',
  Sako85 = 'sako85',
  Historic = 'historic',
  Tikka = 'tikka',
  TRG = 'trg',
  Sako100 = 'sako100',
  Sako90 = 'sako90',
}

export interface GunDataContent {
  id: string
  family: GunFamily
  label: string
  gridImage: string
  bgImage?: string
  year?: number
  tagline?: string
  description?: string
  button?: ProductButton
}

const customSako85Button = (suffix: string): any => ({
  label: 'Select your Sako 85',
  onClick: (): void => {
    window.open('https://choose.sako.fi/global/sako_85/' + suffix, 'sakofi')
  },
})
const customTikkaButton = (suffix: string): any => ({
  label: 'Try Tikka selector',
  onClick: (): void => {
    window.open('https://choose.tikka.fi/global/tikka/' + suffix, 'sakofi')
  },
})

export const gunData: { [id: string]: GunDataContent } = {
  'sakos20-precision': {
    id: GunId.SakoS20Precision,
    family: GunFamily.S20,
    label: 'S20 Precision',
    gridImage: s20Precision,
  },
  'sakos20-hunting': {
    id: GunId.SakoS20Hunting,
    family: GunFamily.S20,
    label: 'S20 Hunting',
    gridImage: s20Hunting,
  },
  'sako85-finnlight2': {
    id: GunId.Sako85Finnlight2,
    family: GunFamily.Sako85,
    label: '85 Finnlight II',
    gridImage: sako85Finnlight2,
    bgImage: sako85Finnlight2Bg,
    tagline: 'Experience an icon reborn',
    description: `Building on the famous Finnlight name, the Sako 85 Finnlight II is an icon reborn. The lightweight RTM fiberglass stock makes the rifle light to carry and the barrel has been treated with a weather resistant Cerakote® coating, which makes this rifle the perfect choice for all conditions. The fully adjustable cheek piece also makes the rifle perfectly customizable for your preferences.`,
    button: customSako85Button('85-finnlight-ii'),
  },
  'sako85-carbonwolf': {
    id: GunId.Sako85Carbonwolf,
    family: GunFamily.Sako85,
    label: '85 Carbon Wolf',
    gridImage: sako85Carbonwolf,
    bgImage: sako85CarbonwolfBg,
    tagline: 'Tailormade for accuracy',
    description: `The ultimate tool for hunters journeying deep into the wilderness. Experience flexibility and effortless mobility with a lightweight synthetic, RTM carbon fiber stock, an adjustable cheek piece and length of pull.`,
    button: customSako85Button('85-carbon-wolf'),
  },
  'sako85-safari': {
    id: GunId.Sako85Safari,
    family: GunFamily.Sako85,
    label: '85 Safari',
    gridImage: sako85Safari,
    bgImage: sako85SafariBg,
    tagline: '',
    description: `The Sako 85 Limited Edition premium Safari rifles are made by Sako's master gunsmiths. These beautiful rifles are made one by one and with an eye for detail and perfection. The Sako 85 Safari is a bolt-action rifle made for the most demanding hunters. Each Safari rifle is hand-finished and the barreled action is epoxy-bedded to the stock to provide you with the best and most durable fit and accuracy.`,
    button: customSako85Button('85-safari'),
  },
  'sako85-bavarian': {
    id: GunId.Sako85Bavarian,
    family: GunFamily.Sako85,
    label: '85 Bavarian',
    gridImage: sako85Bavarian,
    bgImage: sako85BavarianBg,
    tagline: '',
    description: `The Sako 85 Bavarian is a masterpiece, combining a high-grade walnut stock in a Central European tradition with the superior performance and technology of the Sako 85 series. Under its traditional exterior lie new modern features of the Sako 85 action.`,
    button: customSako85Button('85-bavarian'),
  },
  'sako85-blackbear': {
    id: GunId.Sako85BlackBear,
    family: GunFamily.Sako85,
    label: '85 Black Bear',
    gridImage: sako85BlackBear,
    bgImage: sako85BlackBearBg,
    tagline: '',
    description: `The Sako 85 Black Bear has a medium-contour fluted barrel, a matte black stock with soft gripping surfaces and blued action and barrel. Its light weight, compact size and good weather resistance mean it's a perfect choice for the active hunter. The Sako 85 Black Bear is extremely well balanced, making it ideal for situations that require rapid action.`,
    button: customSako85Button('85-black-bear'),
  },
  'sako85-carbonlight': {
    id: GunId.Sako85Carbonlight,
    family: GunFamily.Sako85,
    label: '85 Carbonlight',
    gridImage: sako85Carbonlight,
    bgImage: sako85CarbonlightBg,
    tagline: '',
    description: `The Sako 85 Carbonlight is a high-end rifle designed for gear-oriented enthusiasts who are after any type of game. This ultra-light multi-purpose hunting rifle features a carbon fiber stock with a soft-touch surface, a right-hand palm swell and a cheek piece. With an approximate weight of only 2.4 kg, the Sako 85 Carbonlight with S-action or the new XS-action is exceptionally comfortable to carry, even on long treks. The 2017 models also come with a left-handed option and different action lengths, including M-action.`,
    button: customSako85Button('85-carbonlight'),
  },
  'sako85-grizzly': {
    id: GunId.Sako85Grizzly,
    family: GunFamily.Sako85,
    label: '85 Grizzly',
    gridImage: sako85Grizzly,
    bgImage: sako85GrizzlyBg,
    tagline: '',
    description: `The Sako 85 Grizzly adds prestige to the Sako Bear Series. Its high-grade walnut stock, medium-contour fluted barrel and blued action give it an elegant appearance and make it a pleasure to handle.`,
    button: customSako85Button('85-grizzly'),
  },
  'sako85-syntheticblack': {
    id: GunId.Sako85SyntheticBlack,
    family: GunFamily.Sako85,
    label: '85 Synthetic Black',
    gridImage: sako85SyntheticBlack,
    bgImage: sako85SyntheticBlackBg,
    tagline: '',
    description: `The Sako 85 Synthetic rifle offers hunters and shooters a completely matte black-finished option. The barrel, action, magazine plate and trigger guard are all matching in color with a Soft Touch color-coated stock. If you are looking for true stealth appearance, this is the rifle for you.`,
    button: customSako85Button('85-synthetic-black'),
  },
  'historic-lotta': {
    id: GunId.HistoricLotta,
    family: GunFamily.Historic,
    label: 'Sako Lotta rifle',
    gridImage: historicLotta,
    bgImage: historicLottaBg,
    description: `In 1923, the Lotta rifle, i.e. the m/24 model, became the first rifle assembled as a mass production in Sako's factory. The name of the rifle comes from the voluntary organisation Lotta Svärd which had an important role as a supporter of Finnish national defence. The organisation raised funds for things such as the purchasing of new barrels and repairing of old weapons. The new barrels were procured from Switzerland. The gun was extremely accurate, thanks to the thicker barrel and the barrel's inside dimensions which were tighter than those of the original m 1891.`,
    year: 1923,
  },
  'historic-suomi': {
    id: GunId.HistoricSuomi,
    family: GunFamily.Historic,
    label: 'Tikka Suomi machine pistol M31',
    gridImage: historicSuomi,
    bgImage: historicSuomiBg,
    description: `The Suomi machine pistol was designed by Aimo Lahti in 1924. The gun was manufactured by Konepistooli Osakeyhtiö, which was established by Lahti, lieutenant Y. Koskinen, captain V. Korpela and lieutenant L. Boyer-Spoof,  and which sold the manufacturing rights to the Tikkakoski gun factory in the early 1930s. About 81,000 units of the weapon were manufactured in the Tikkakoski gun factory between the years 1931 and 1945. In addition to that, licenced manufacturing took place in Sweden, Denmark and Switzerland for the army of the respective country.`,
    year: 1924,
  },
  'historic-pystykorva-m28': {
    id: GunId.HistoricPystykorvaM28,
    family: GunFamily.Historic,
    label: 'Sako Pystykorva M28 (‘The Spitz’) ',
    gridImage: historicPystykorvaM28,
    bgImage: historicPystykorvaM28Bg,
    description: `The reformed weapon model of the Mosing-Nagant m. 1891 of Suojeluskuntain ase- ja konepaja (“The armory and engineer works of the White Guard”). One of the most significant developmental steps of the White Guard's M-28 was the protected front sight. This front sight had already been used in the infantry rifle M-27. Thanks to the sight model, the rifle was nicknamed ‘the Spitz’ and the first Spitz was the M-27 from which the name spread to and lived on in later models. The M-28 rifle proved to be even better than similar weapons being used by the defence forces, in terms of its quality.`,
    year: 1928,
  },
  'historic-pystykorva-m28/30': {
    id: GunId.HistoricPystykorvaM2830,
    family: GunFamily.Historic,
    label: 'Sako M/28-30',
    gridImage: historicPystykorvaM2830,
    bgImage: historicPystykorvaM2830Bg,
    description: `The reformed weapon model of the Mosing-Nagant m. 1891 of Suojeluskuntain ase- ja konepaja (“The armory and engineer works of the White Guard”). Infantry rifle M-28, or more familiarly ‘the Spitz’, proved to be even better than similar weapons being used by the Finnish Defence Forces, in terms of its quality. The rear sight of this model was developed by Harry Mansner. The early production used the M-28 front sight, but later on came the model in which the front protector had been widened and the sight's horisontal adjustment had been implemented with extremely precise adjustment screws. The barrels are of Finnish steel. It was a significant acknowledgement for Sako that in the World Championships of 1937, all rifle sports used the M/-28–30 rifles.`,
    year: 1937,
  },
  'historic-ukkopekka': {
    id: GunId.HistoricUkkopekka,
    family: GunFamily.Historic,
    label: 'Sako M39 Ukkopekka',
    gridImage: historicUkkopekka,
    bgImage: historicUkkopekkaBg,
    description: `A weapon of the White Guard and the infantry, it was developed from the Mosin-Nagant m. 1891 rifles of Sako Oy and the Finnish army and named after P.E. Svinhufvud, the third president of Finland. P.E. Svinhufvud was elected the first Chairman of the Board of Sako in 1927. What separates Ukko-Pekka from the Spitz is the different frontend and the redesigned stock with a grip and a buttstock belt fastening that does not go through the stock. The barrel is lighter and its inside dimensions are more spacious so that the gun is more appropriate for shooting Russian cartridges obtained as spoils of war. For the same reason, a thicker and heavier 13g D-166 bullet was developed for the M-39.  The image shows the early pre-1941 Ukko-Pekka without the grip. The grip was introduced into the model after this.`,
    year: 1939,
  },
  'historic-l46': {
    id: GunId.HistoricL46,
    family: GunFamily.Historic,
    label: 'Sako L46',
    gridImage: historicL46,
    bgImage: historicL46Bg,
    description: `The L46 hunting rifle conceived during the war was introduced into production in 1946.  It was well-received in Finland, the Nordic countries and western Europe. The most significant breakthrough was Sako's entry into the US market. Jan Winter, an agent of the American company Firearms International, had acquired a Sako L46 and was ready to sell it in the USA. Thanks to the L46, Sako's US exports increased every year in the 1950s and in 1953, the result surpassed even domestic sales and the United States became Sako's most significant export destination country. The L46 is Sako's first own rifle model in which the lock was designed to meet the requirements of Sako's first own calibre 7x33 developed at the same time. The L46 is a rifle designed for hunting birds or small game and 7x33 is the calibre appropriate for it. The gun also has a detachable machine, which was rare for the civilian weapons of the time when the L46 was designed. The first model to use the Sako's trademark binocular mount that widens forward.`,
    year: 1946,
  },
  'historic-l57': {
    id: GunId.HistoricL57,
    family: GunFamily.Historic,
    label: 'Sako L57',
    gridImage: historicL57,
    bgImage: historicL57Bg,
    description: `A hunting rifle designed for medium-sized game, such as deer, and which was designed its own lock to suit the calibres .308 and .243 that were becoming globally popular at the time. The hunting rifle's firing pin safety was similar to the one in the Sako L46 hunting rifle. The firing pin safety, a lever that moves back and forth, is located in the back part of the lock and when it is in the back position, the safety is on. The gun has a fixed magazine for six cartridges. Thanks to this model, Sako's US sales grew even more than before.`,
    year: 1957,
  },
  'historic-vixen': {
    id: GunId.HistoricVixen,
    family: GunFamily.Historic,
    label: 'Sako L461 Vixen',
    gridImage: historicVixen,
    bgImage: historicVixenBg,
    description: `A light hunting rifle designed for small game. It was developed from the Sako L46 model. The calibre selection was expanded. The detachable magazine was turned into a fixed magazine with a bottom that has hinges on the front. The model was in production for a long time, about 30 years.`,
    year: 1961,
  },
  'historic-finnbear': {
    id: GunId.HistoricFinnbear,
    family: GunFamily.Historic,
    label: 'Sako L61R Finnbear',
    gridImage: historicFinnbear,
    bgImage: historicFinnbearBg,
    description: `The model developed for hunting big game in 1961 was the first big game rifle manufactured to be compatible with Sako's own lock. The weapon is every hunter's trusted weapon that is traditional and reliable and has a two-baffled muzzle brake.`,
    year: 1961,
  },
  'historic-rk62': {
    id: GunId.HistoricRK62,
    family: GunFamily.Historic,
    label: 'Sako/Valmet RK 62',
    gridImage: historicRK62,
    bgImage: historicRK62Bg,
    description: `The assault rifle RK 62 developed together with the Finnish Defence Forces was manufactured in both Valmet's Tourula factory and Sako's Riihimäki factory. In the 1970s, RK62 was the service weapon used by every conscript.`,
    year: 1962,
  },
  'historic-finnwolf': {
    id: GunId.HistoricFinnwolf,
    family: GunFamily.Historic,
    label: 'Sako VL63 Finnwolf',
    gridImage: historicFinnwolf,
    bgImage: historicFinnwolfBg,
    description: `As Sako’s rifle imports grew, especially in relation to the US, the market expressed its interest in a rifle with a lever lock. The designing of the new weapon began in 1958. The VL63 was Sako’s first rifle that both right- and left-handed shooters could use, thanks to its lever lock.`,
    year: 1963,
  },
  'historic-m55': {
    id: GunId.HistoricTikkaM55,
    family: GunFamily.Historic,
    label: 'Tikka M-55',
    gridImage: historicTikkaM55,
    bgImage: historicTikkaM55Bg,
    description: `A bolt-locked medium-sized hunting rifle that has a detachable magazine and adjustable trigger mechanism and fixed mount for binoculars. The spring resistance of the trigger can be adjusted with the screw at the back wall of the magazine well. A very popular weapon that is used even today for hunting all over the world.`,
    year: 1968,
  },
  'historic-m65': {
    id: GunId.HistoricTikkaM65,
    family: GunFamily.Historic,
    label: 'Tikka M-65',
    gridImage: historicTikkaM65,
    bgImage: historicTikkaM65Bg,
    description: `A sturdy bolt-locked hunting rifle. The size of the lock enabled using the Magnum calibres suitable for hunting big game.  The weapon has an adjustable trigger mechanism and detachable magazine. A very popular weapon that is used even today for hunting all over the world.`,
    year: 1969,
  },
  'historic-m07': {
    id: GunId.HistoricTikkaM07,
    family: GunFamily.Historic,
    label: 'Tikka M-07 Shotgun Groove',
    gridImage: historicTikkaM07,
    bgImage: historicTikkaM07Bg,
    description: `Into the Tikka M07 shotgun groove, which was introduced in the market in 1970, was added a scope rail. The trigger mechanism was also changed and developed further than its predecessor Tikka M66.`,
    year: 1976,
  },
  'historic-trg21': {
    id: GunId.HistoricTRG21,
    family: GunFamily.Historic,
    label: 'Sako TRG-21 & 41',
    gridImage: historicTRG21,
    bgImage: historicTRG21Bg,
    description: `Sako’s first target rifle model, suitable for both target shooting and law enforcement use. The very successful and recognised models Sako TGR 22 and 42, widely used by law enforcement around the world, are based on this model. The modular structure has a bolt lock in which the parts are connected from the aluminium frame to the stock rail.`,
    year: 1989,
  },
  'historic-m558': {
    id: GunId.HistoricTikkaM558,
    family: GunFamily.Historic,
    label: 'Tikka M-558',
    gridImage: historicTikkaM558,
    bgImage: historicTikkaM558Bg,
    description: `The first Tikka model manufactured in Riihimäki.`,
    year: 1989,
  },
  'historic-triace': {
    id: GunId.HistoricTriace,
    family: GunFamily.Historic,
    label: 'Sako Triace, a calibre-convertible pistol',
    gridImage: historicTriace,
    bgImage: historicTriaceBg,
    description: `A semi-automatic pistol designed for Olympics, regular and sport pistol shooting that has convertible calibre and a changeable barrel slide for the stock. The accurate pistol was manufactured with a wooden grip that could be adjusted according to the size of the shooter’s hand. The firing pressure changes automatically to suit different sports when the calibre is changed.`,
    year: 1980,
  },
  'historic-rk95': {
    id: GunId.HistoricRK95,
    family: GunFamily.Historic,
    label: 'Sako RK 95',
    gridImage: historicRK95,
    bgImage: historicRK95Bg,
    description: `An assault rifle developed in cooperation with the Finnish Defence Forces. It was designed and manufactured in Sako’s Riihimäki factory. The development took into account the targets for development that were noticed in the RK 62 model. A folding buttstock and a lock-up gas valve that enabled the use of rifle grenades.`,
    year: 1995,
  },
  'historic-75': {
    id: GunId.Historic75,
    family: GunFamily.Historic,
    label: 'Sako 75',
    gridImage: historic75,
    bgImage: historic75Bg,
    description: `Sako’s 75th anniversary was celebrated with a new weapons range. The Sako 75 product range, which was the result of the largest product development project in Sako’s history, was extremely successful. Sako felt that all the hunting rifles manufacturer prior to it had more or less been based on the first hunting models designed after the war. The aim was to use the new range to make, for the first time, a completely new weapon construction. This time, they began with a clean slate. In preparation for that they carried out exceptionally comprehensive user surveys, based on which the designing of the weapon was started. The new range had significant technical reformations. The reliability of operation was improved by the three muzzle brakes of the lock frame and mechanical ejection of the shell. As a result of the user surveys, the range included a synthetic stock with a rubber-like grip surface. Additionally, the Sako weapons had entirely rustproof metal parts for the first time. The detachable magazine was also a new feature. The good reputation and support the sales and success of the Sako 75 range brought for the company brand can be seen as a special milestone.`,
    year: 1997,
  },
  'historic-trg42': {
    id: GunId.HistoricTRG42,
    family: GunFamily.Historic,
    label: 'Sako TRG-22 & 42',
    gridImage: historicTRG42,
    bgImage: historicTRG42Bg,
    description: `The development project was carried out in cooperation with the Finnish Defence Forces. The Sako TGR 22 and 42 are widely used by law enforcement around the world. The law enforcement models are based on the basic model available in the civilian market, but almost every delivery to the authorities has been tailored in some way.`,
    year: 1999,
  },
  'historic-512s': {
    id: GunId.HistoricTikka512S,
    family: GunFamily.Historic,
    label: 'Tikka Shotgun 512S',
    gridImage: historicTikka512S,
    bgImage: historicTikka512SBg,
    description: `All Tikka 512S shotguns are based on the Valmet 412 shotgun designed in Finland. After the Tikka 512S, the production was moved to Italy, the Marocchi factory, at which point the name of the shotgun was changed to Tikka. These shotguns are still produced today in the Marocchi factory; they are now called Finnclassic. The old Valmet barrels are still compatible with the newer Finnclassic shotguns.`,
    year: 2000,
  },
  'historic-t3': {
    id: GunId.HistoricTikkaT3,
    family: GunFamily.Historic,
    label: 'Tikka T3',
    gridImage: historicTikkaT3,
    bgImage: historicTikkaT3Bg,
    description: `The premise of the design was to decrease the manufacturing costs and to have a weapon of a new price segment. All savings in costs were achieved without compromising the reliability of operation, accuracy or safety. In this way, an excellent price-quality ratio could be reached. The Tikka T3 range has been a major sales success; Sako manufactured its millionth unit in March 2020. The most important cost savings were achieved by designing parts that could be manufactured by injection-moulding different fibreglass-reinforced materials. Furthermore, only one bolt-lock length was designed for all calibres. The changes required by the calibres were implemented with magazines and locks of different sizes. The Tikka T3 Lite model was Sako’s first to win the NRA’s award for the weapon of the year. In the Shot Show, the Tikka T3 was also awarded the Best Buy! award of the exhibition.`,
    year: 2002,
  },
  'historic-t3-tactical': {
    id: GunId.HistoricTikkaT3Tactical,
    family: GunFamily.Historic,
    label: 'Tikka T3 Tactical',
    gridImage: historicTikkaT3Tactical,
    bgImage: historicTikkaT3TacticalBg,
    description: `The Tikka T3 Tactical was developed as a target rifle of medium price range that can be used by both civilians and law enforcement. The National Rifle Association awarded the Tikka T3 Tactical the weapon of the year award in 2007.`,
    year: 2004,
  },
  'historic-quad': {
    id: GunId.HistoricSakoQuad,
    family: GunFamily.Historic,
    label: 'Sako Quad',
    gridImage: historicQuad,
    bgImage: historicQuadBg,
    description: `Sako Quad is a small-bore rifle whose calibre range includes four different calibres. The barrel can be changed with the toolkit sold with the weapon. Each replacement barrel has its own serial number. The easily operated barrel change structure of the Sako Quad has been patented. The barrels and magazines are colour-coded, which means that the compatible parts are easy to identify. The convertible calibre enables different kinds of practice and hunting shootings.`,
    year: 2006,
  },
  'historic-85': {
    id: GunId.HistoricSako85,
    family: GunFamily.Historic,
    label: 'Sako 85',
    gridImage: historicSako85,
    bgImage: historicSako85Bg,
    description: `In 2006, which was ten years after the previous large launch of Sako hunting rifles, a new Sako 85 range was introduced into the market. It was largely based on the Sako 75. The majority of the changes were based on the new manufacturing techniques, especially in relation to the manufacturing of the main components. The Sako 85 range has six different lock sizes to ensure optimal calibre operation. Other changes to the weapon, such as improved lock control, the so-called “stick safety” of the magazine and the forced feeding of cartridges, were made on the basis of the feedback.`,
    year: 2006,
  },
  'historic-a7': {
    id: GunId.HistoricSakoA7,
    family: GunFamily.Historic,
    label: 'Sako A7',
    gridImage: historicSakoA7,
    bgImage: historicSakoA7Bg,
    description: `Designed for the US market as a product of the medium price segment. Between the Sako 85 and Tikka T3 ranges. The Sako A7 model has, among other features, a lock with a three-baffled muzzle brake, detachable magazine that is loaded from above and which feeds from the middle, and the Weaver-type binocular mount fastened with screws.`,
    year: 2008,
  },
  'historic-trg-m10': {
    id: GunId.HistoricSakoTRGM10,
    family: GunFamily.Historic,
    label: 'Sako TRG M10',
    gridImage: historicSakoTRGM10,
    bgImage: historicSakoTRGM10Bg,
    description: `The Sako TRG M10 is a bolt-locked modular target rifle. The calibre of the rifle can be adjusted by changing the barrel, lock and magazine. The weapon has a folding buttstock whose ergonomic features can be adjusted versatilely. The cheekpiece and the controls of the drag length are spring-loaded so that the shooter does not need to change their shooting position to adjust them. The compatibility with different calibres enables shooting with different calibres. The Sako TRG M10 is a next-generation target rifle built on top of the good reputation of the Sako TRG models.`,
    year: 2011,
  },
  'historic-t3x': {
    id: GunId.HistoricTikkaT3x,
    family: GunFamily.Historic,
    label: 'Tikka T3x',
    gridImage: historicTikkaT3x,
    bgImage: historicTikkaT3xBg,
    description: `The Tikka T3 and its reformed model T3x have been global sales successes and a total of over million units of them have already been manufactured.The biggest change were the stock’s changeable grip surfaces that sold as accessories. The ejection port was expanded and the material of the connector was changed to metal. The rear plate was designed to decrease recoil. The expansive range includes rifles for different purposes, from target rifles to traditional hunting rifles. The stocks also have a range of colours, patterns and surface materials.`,
    year: 2016,
  },
  'historic-t3x-tact-a1-military': {
    id: GunId.HistoricTikkaT3xTactA1Military,
    family: GunFamily.Historic,
    label: 'Tikka T3x Tact A1, MIL launch',
    gridImage: historicT3xTactA1,
    bgImage: historicT3xTactA1Bg,
    description: `An excellent target rifle of medium price range whose largest civilian markets have been in the US. The rifle has a modular stock structure that conforms to the shooter’s personal preferences. The stock is based on the aluminium frame, ensuring excellent precision. Additionally, the target rifle has been equipped with a metal magazine for ten cartridges.`,
    year: 2017,
  },
  'historic-t3x-tact-a1-civilian': {
    id: GunId.HistoricTikkaT3xTactA1Civilian,
    family: GunFamily.Historic,
    label: 'Tikka T3x Tact A1, civilian launch',
    gridImage: historicT3xTactA1,
    bgImage: historicT3xTactA1Bg,
    description: `An excellent target rifle of medium price range whose largest civilian markets have been in the US. The rifle has a modular stock structure that conforms to the shooter’s personal preferences. The stock is based on the aluminium frame, ensuring excellent precision. Additionally, the target rifle has been equipped with a metal magazine for ten cartridges. The product has also gained a foothold in law enforcement use.`,
    year: 2017,
  },
  'historic-c19-ranger': {
    id: GunId.HistoricTikkaC19,
    family: GunFamily.Historic,
    label: 'Tikka C19 Ranger Rifle',
    gridImage: historicC19Ranger,
    bgImage: historicC19RangerBg,
    description: `In Canada, Sako won an important law enforcement competition when the state chose the Tikka T3 rifle as the new service weapon for the paramilitary Canadian Rangers department. The rifle is designed for extreme weather conditions and it passed the demanding tests in which the selection criteria included performance and security of operation.`,
    year: 2018,
  },
  'historic-t1x': {
    id: GunId.HistoricTikkaT1x,
    family: GunFamily.Historic,
    label: 'Tikka T1x',
    gridImage: historicT1x,
    bgImage: historicT1xBg,
    description: `The small-bore rifle Tikka T1x MTR launched in 2018 was an anticipated addition to the Tikka product range and it was well-received both globally and in Finland.  The very easily operated lock and good firing feel are of the same high Tikka quality as those in the larger rifles. The Tikka T1x uses the same stock as the T3x, which means that moving on to a larger weapon is effortless if the shooter has started their practicing with a T1x small-bore rifle. The accessories of the Tikka T3x are also compatible with the T1x.`,
    year: 2018,
  },
  'historic-s20': {
    id: GunId.HistoricSakoS20,
    family: GunFamily.Historic,
    label: 'Sako S20',
    gridImage: historicSakoS20,
    bgImage: historicSakoS20Bg,
    description: `In 2020, Sako boldly defined a new bolt-lock rifle category. The Sako S20 is the first real hybrid rifle that changes with the shooter’s needs. In the launch, Sako presented the models equipped with a tactical stock and ergonomic hunting stock. The latter of the stocks is Sako’s first ever stock to have a thumbhole. The inspiration for the tactical stock and aluminium frame was the Sako TRG. Changing the stocks is quick and easy; in practice, the rifle can be changed from a tactical rifle into a hunting rifle and back in just a few minutes. The frame of the stock is a high-quality aluminium frame that absorbs the recoil. The product line also contains a comprehensive selection of accessories.`,
    year: 2020,
  },
  'historic-100': {
    id: GunId.HistoricSako100,
    family: GunFamily.Historic,
    label: 'Sako 100',
    gridImage: sako100Wood,
    bgImage: historicSako100Bg,
    description: `The Sako 100 is the culmination of 100 years of Sako rifle development – the very best we have to offer. A true premium hunting rifle with features to satisfy the needs of the passionate hunters and outdoorsmen. The switch caliber option, the robust all-metal structure and the high-quality adjustable trigger mechanism with advanced safety features make the rifle suitable for different game, seasons and hunting situations near and far. In short, the Sako 100 is the ultimate rifle for explorers.`,
    year: 2022,
  },
  'historic-90': {
    id: GunId.HistoricSako90,
    family: GunFamily.Historic,
    label: 'Sako 90',
    gridImage: sako90Adventure,
    bgImage: historicSako90Bg,
    description: `Focus on the journey. On all those remarkable moments in nature that take your breath away. Focus on your senses. They help you understand and perceive the world around you – to be truly aware of the things surrounding you. To experience exceptional things, you must put yourself in exceptional places. In nature you can truly be reborn.
    <br/><br/>Just like you, our most advanced turn-bolt action rifles evolve and improve to be even smoother, lighter and accurate for your journey. Sako 90 is the ultimate rifle series, reborn to enhance your experiences in the wild. The supreme companion for your journeys, developed together with you – the hunter.`,
    year: 2023,
  },
  'tikka-t1x-mtr': {
    id: GunId.TikkaT1xMTR,
    family: GunFamily.Tikka,
    label: 'T1x MTR',
    gridImage: tikkaT1xMTR,
    bgImage: tikkaT1xMTRBg,
    tagline: 'The best of both worlds',
    description: `The Tikka T1x rimfire rifle is designed to handle a wide range of shooting tasks. Its unique barrel has a cross-over profile, which offers you the benefits and stability of a heavier barrel without noticeably increasing the overall weight. It's a rimfire rifle of unparalleled quality and craftsmanship that performs perfectly at the range and in rough terrain.`,
    button: {
      type: 'internal',
      label: 'Watch video',
      popupTarget: ContentId.TikkaT1xMTRVideo,
    },
  },
  'tikka-t3x-compact-tactical': {
    id: GunId.TikkaT3xCompactTactical,
    family: GunFamily.Tikka,
    label: 'T3x Compact Tactical Rifle',
    gridImage: tikkaT3xCompactTactical,
    bgImage: tikkaT3xCompactTacticalBg,
    tagline: 'High performance in all situations',
    description: `T3x Compact tactical rifle is a multipurpose rifle that adapts to any given situation. The Tikka T3x Compact Tactical Rifle features a 10-round steel magazine and comes standard with vertical angled grip for prone shooting.`,
    button: customTikkaButton('t3x-compact-tactical-rifle'),
  },
  'tikka-t3x-hunter': {
    id: GunId.TikkaT3xHunter,
    family: GunFamily.Tikka,
    label: 'T3x Hunter',
    gridImage: tikkaT3xHunter,
    bgImage: tikkaT3xHunterBg,
    tagline: undefined,
    description: `The Tikka T3x Hunter is designed for the shooter who appreciates the warm feel of a wooden stock combined with solid performance. The T3x Hunter offers an extensive caliber selection for hunting and sport-shooting purposes. It is built with traditional principles and modern innovation, providing you with a new level of accuracy, reliability, and enjoyment.`,
    button: customTikkaButton('t3x-hunter'),
  },
  'tikka-t3x-lite-polyfade': {
    id: GunId.TikkaT3xLitePolyfade,
    family: GunFamily.Tikka,
    label: 'T3x Lite Polyfade',
    gridImage: tikkaT3xLitePolyfade,
    bgImage: tikkaT3xLitePolyfadeBg,
    tagline: 'Get there before the animals see you',
    description: `T3x Polyfade, with its class-leading digital camo blending technology, is for the hunter who wants to blend into his surroundings.`,
    button: customTikkaButton('t3x-lite-polyfade'),
  },
  'tikka-t3x-lite-roughtech': {
    id: GunId.TikkaT3xLiteRoughtech,
    family: GunFamily.Tikka,
    label: 'T3x Lite Roughtech',
    gridImage: tikkaT3xLiteRoughtech,
    bgImage: tikkaT3xLiteRoughtechBg,
    tagline: 'Hold on for year-round pinpoint precision',
    description: `All the benefits and performance of a T3x Lite model combined with a Roughtech stock. The rough surface texture provides a solid grip throughout the stock in all weather conditions. Muzzle brake is included for recoil reduction.`,
    button: customTikkaButton('t3x-lite-roughtech'),
  },
  'tikka-t3x-tact-a1': {
    id: GunId.TikkaT3xTactA1,
    family: GunFamily.Tikka,
    label: 'T3x TACT A1',
    gridImage: tikkaT3xTactA1,
    bgImage: tikkaT3xTactA1Bg,
    tagline: 'The game changer',
    description: `Never before has there been a rifle that meets the strictest quality requirements for unrivalled long range accuracy at this price range. With best-in-class value for every sport shooter, the new T3x TACT A1 is the ultimate tool for long range accuracy.`,
    button: {
      type: 'internal',
      label: 'Watch video',
      popupTarget: ContentId.TikkaT3xTactA1Video,
    },
  },
  'tikka-t3x-upr': {
    id: GunId.TikkaT3xUPR,
    family: GunFamily.Tikka,
    label: 'T3x UPR',
    gridImage: tikkaT3xUPR,
    bgImage: tikkaT3xUPRBg,
    tagline: 'High performance in all situations',
    description: `T3x Ultimate Precision Rifle introduces a new kind of lightweight stock construction: the bedding features an extra layer of carbon fiber mixed with fiberglass for increased rigidity and accuracy. The stock is coated with a rough surface for the best grip in any condition and has attachments for QD slings. You can choose between single stage or set trigger. T3x UPR rifles are all muzzle threaded with 5/8x24 thread.`,
    button: customTikkaButton('t3x-upr'),
  },
  'tikka-t3x-lite-veil-alpine': {
    id: GunId.TikkaT3xLiteVeilAlpine,
    family: GunFamily.Tikka,
    label: 'T3x Lite Veil Alpine',
    gridImage: tikkaT3xLiteVeilAlpine,
    bgImage: tikkaT3xLiteVeilAlpineBg,
    tagline: 'Goes wherever the hunt takes you',
    description: `T3x Lite Veil Alpine is the perfect companion for mountain terrains and snowy landscapes. Cerakote coating on the barrel enhances weather durability. Muzzle brake is included for recoil reduction.`,
    button: customTikkaButton('t3x-lite-veil-alpine'),
  },
  'sakotrg-42': {
    id: GunId.SakoTRG42,
    family: GunFamily.TRG,
    label: 'TRG 22/42 A1',
    gridImage: trg42,
    bgImage: trg42Bg,
    tagline: 'Precision perfected',
    description: `The Sako TRG 22 A1 & 42 A1 are robust precision instruments made for one specific task: hit the target – whatever it takes. The 2018 models are taken to the next level with brand new features inspired by our flagship model TRG M10.`,
  },
  'sakotrg-m10': {
    id: GunId.SakoTRGM10,
    family: GunFamily.TRG,
    label: 'TRG M10',
    gridImage: trgM10,
    bgImage: trgM10Bg,
    tagline: undefined,
    description:
      `The TRG M10 is a bolt-action sniper rifle that is available in multiple calibers, manually operated and shoulder-fired, as well as magazine-fed. It has a high-capacity magazine and fully adjustable stock that make it a multi-functional system in a single weapon, suitable for many different circumstances.<br/><br/>` +
      `A high level of configurability make the TRG M10 an extremely versatile platform: it easily converts from a true long-range precision rifle to a more compact piece of equipment.`,
  },
  'sako100-wood': {
    id: GunId.Sako100Wood,
    family: GunFamily.Sako100,
    label: 'Sako 100 Explorer Wood',
    gridImage: sako100Wood,
    bgImage: sako100WoodBg,
    tagline: 'The rifle for explorers',
    description: `The Sako 100 is the culmination of 100 years of Sako rifle development – the very best we have to offer. A true premium hunting rifle with features to satisfy the needs of the passionate hunters and outdoorsmen. The switch caliber option, the robust all-metal structure and the high-quality adjustable trigger mechanism with advanced safety features make the rifle suitable for different game, seasons and hunting situations near and far. In short, the Sako 100 is the ultimate rifle for explorers.`,
  },
  'sako100-carbon': {
    id: GunId.Sako100Carbon,
    family: GunFamily.Sako100,
    label: 'Sako 100 Explorer Carbon',
    gridImage: sako100Carbon,
    bgImage: sako100CarbonBg,
    tagline: 'The rifle for explorers',
    description: `The Sako 100 is the culmination of 100 years of Sako rifle development – the very best we have to offer. A true premium hunting rifle with features to satisfy the needs of the passionate hunters and outdoorsmen. The switch caliber option, the robust all-metal structure and the high-quality adjustable trigger mechanism with advanced safety features make the rifle suitable for different game, seasons and hunting situations near and far. In short, the Sako 100 is the ultimate rifle for explorers.`,
  },
  'sako90-peak': {
    id: GunId.Sako90Peak,
    family: GunFamily.Sako90,
    label: 'Sako 90 Peak',
    gridImage: sako90Peak,
    bgImage: sako90PeakBg,
    tagline: 'THE RIFLE FOR MOUNTAIN HUNTERS',
    description: `The Sako 90 Peak is one of the lightest bolt action rifles on the market, built on the renewed Carbonlight platform. It is designed for the ultimate outdoorsman looking for the perfect tool for the mountain hunt of a lifetime. This ultra-light hunting rifle features a carbon fibre stock with a texturized surface, right-hand palm swell and cheek support. With an approximate weight of 2.6 kg, the Sako 90 Peak with S-action is exceptionally comfortable to carry, even on long treks. It is also available in a left-handed version and with different action lengths, including M-action. The SAKO 90 Peak is the ultimate rifle for mountain hunters.`,
  },
  'sako90-quest-ultra': {
    id: GunId.Sako90QuestUltra,
    family: GunFamily.Sako90,
    label: 'Sako 90 Quest Ultra',
    gridImage: sako90QuestUltra,
    bgImage: sako90QuestUltraBg,
    tagline: 'THE RIFLE FOR BACKCOUNTRY HUNTERS',
    description: `The Sako 90 Quest Ultra is designed for the demanding backcountry hunter who is looking for an uncompromised, versatile hunting rifle with high-level adjustability and ergonomics. Easy length-of-pull adjustments and the cheek riser ensure that you will always find the perfect shooting posture and view through the scope and the Picatinny receiver allows for flexible scope mounting. The new coating on the carbon fibre stock aids in concealment at higher elevations and the texturized surface ensures perfect grip in all weather conditions. The carbon fibre wrapped stainless steel barrel provides good balance and durability without compromising performance and accuracy. The barreled action is coated with Tungsten Cerakote for ultimate weather resistance.`,
  },
  'sako90-quest': {
    id: GunId.Sako90Quest,
    family: GunFamily.Sako90,
    label: 'Sako 90 Quest',
    gridImage: sako90Quest,
    bgImage: sako90QuestBg,
    tagline: 'PERFORMANCE AND COMFORT IN A LIGHT PACKAGE',
    description: `The Sako 90 Quest is designed for the backcountry hunter who is looking for a versatile hunting rifle with high-level adjustability and ergonomics. Carbon Wolf was a ground-breaking hunting stock for a production rifle, and the Sako 90 Quest continues the story. The multi-adjustable carbon fibre stock, for superb shooting ergonomics, and RTM technology, for dimensionally accurate and effectively recoil-absorbing stock construction, together ensure the perfect shot wherever you roam. The new coating on the stock offers perfect grip in any condition and the Cerakoted stainless steel barreled action ensures your rifle is ready for action even after days of hiking.`,
  },
  'sako90-hunter': {
    id: GunId.Sako90Hunter,
    family: GunFamily.Sako90,
    label: 'Sako 90 Hunter',
    gridImage: sako90Hunter,
    bgImage: sako90HunterBg,
    tagline: 'THE RIFLE FOR HUNTERS',
    description: `The Sako 90 Hunter comes with world-class Sako features, including legendary Sako accuracy, smooth bolt operation and perfect balance, making it a valued rifle to both own and shoot. The design combines a walnut stock with either blued or stainless metal parts. Open sights are available for the blued barrel option. The popular Hunter stock continues to fulfil the needs of many hunters who are looking for a straightforward and elegant walnut stock rifle for versatile hunting. Combined with either a blued or stainless barreled action, stainless steel bolt and the new Sako 90 Optilock interface for optics mounting, the Sako 90 guarantees reliable and safe operation. All this makes the Sako 90 Hunter a truly classic rifle for dedicated hunters.`,
  },
  'sako90-adventure': {
    id: GunId.Sako90Adventure,
    family: GunFamily.Sako90,
    label: 'Sako 90 Adventure',
    gridImage: sako90Adventure,
    bgImage: sako90AdventureBg,
    tagline: 'THE RIFLE FOR ADVENTURERS',
    description: `The Sako 90 Adventure takes usability and practicality to a whole new level. The adjustable fibreglass stock and Tungsten Cerakote-coated stainless steel action make the rifle durable in any condition and offers high-level ergonomics for many hunting disciplines. The lightness of the rifle makes it easy to carry, the wide selection of calibres make it usable for most game animals and the material technology solutions used ensure reliability in any environment. The rigidity and accuracy of the rifle is further improved by adding carbon fibre reinforcements around the receiver and bedding surfaces.`,
  },
  'sako90-bavarian': {
    id: GunId.Sako90Bavarian,
    family: GunFamily.Sako90,
    label: 'Sako 90 Bavarian',
    gridImage: sako90Bavarian,
    bgImage: sako90BavarianBg,
    tagline: 'THE RIFLE FOR ADVENTURERS',
    description: `The Sako 90 Bavarian continues the legacy of elegant walnut-stock hunting rifles. The stock geometry pays homage to Central-European tradition of rifle stocks with a hog’s back rear stock shape. This beautiful stock was first designed for high-seat hunting during which the shooting angle can change quickly from level to downwards. The hog’s back rear stock design offers superior ergonomics for such situations. The Bavarian is always equipped with a set trigger to match the need of the most demanding hunters. The Optilock receiver makes sure you have a clear view through open sights, which are often mounted on the Bavarian. The wide selection of compatible calibres broadens the usage of this beautiful rifle for the elegance-oriented hunter.`,
  },
  'sako90-varmint': {
    id: GunId.Sako90Varmint,
    family: GunFamily.Sako90,
    label: 'Sako 90 Varmint',
    gridImage: sako90Varmint,
    bgImage: sako90VarmintBg,
    tagline: 'THE RIFLE FOR SMALL GAME AND VERMIN HUNTERS',
    description: `Built for long-range shooting and hunting, the Sako 90 Varmint offers rigidity and stability for pinpoint accuracy. The free-floating heavy stainless steel barrel guarantees that you stay on target round after round. Fluting on the barrel gives a distinct appearance while also allowing heat to dissipate. The rigid and stiff birch-laminate stock with raised Monte Carlo comb and wide-bottom forend enables a perfect shooting posture when using high-magnifying optics. The Sako 90 Varmint truly is the perfect Varmint rifle for the dedicated Hunter.`,
  },
}
