import { createGlobalStyle } from 'styled-components'

const prefix = '/fonts'
export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'DINNextW01Regular';
    src: url(${prefix}/din-next-w01-regular.eot?#iefix);
    src: url(${prefix}/din-next-w01-regular.eot?#iefix) format('eot'),
      url(${prefix}/din-next-w01-regular.woff) format('woff'),
      url(${prefix}/din-next-w01-regular.ttf) format('truetype');
  }
  @font-face {
    font-family: 'DINNextW01Medium';
    src: url(${prefix}/din-next-w01-medium.eot?#iefix);
    src: url(${prefix}/din-next-w01-medium.eot?#iefix) format('eot'),
      url(${prefix}/din-next-w01-medium.woff) format('woff'),
      url(${prefix}/din-next-w01-medium.ttf) format('truetype');
  }
  @font-face {
    font-family: 'DINNextW01Bold';
    src: url(${prefix}/din-next-w01-bold.eot?#iefix);
    src: url(${prefix}/din-next-w01-bold.eot?#iefix) format('eot'),
      url(${prefix}/din-next-w01-bold.woff) format('woff'),
      url(${prefix}/din-next-w01-bold.ttf) format('truetype');
  }
  body {
    font-family: DINNextW01Regular, sans-serif;
    margin: 0;
  }
`
