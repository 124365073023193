import React, { useState } from 'react'
import styled from 'styled-components'
import { Hotspot, Scene, SceneAndHotspot } from '../../Constants'
import { useAppState } from '../../GlobalState'
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable'
import { useWindowSize } from '../../hooks/WindowSize'

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 72px;
  width: 100vw;
  height: 160px;
  background-color: transparent;
  z-index: 2020;
  overflow: hidden; // hide scrollbars
`

const ProductScroller = styled.div`
  margin: 0 8px 0;
  display: grid;
  grid-gap: 8px;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  grid-auto-columns: 160px;
  overflow-x: visible;
  // ~164px * number of boxes
  @media (min-width: 1180px) {
    justify-content: center; // causes an issue with narrow screens => cannot scroll to the left.
  }
`

const ProductBox = styled.div`
  height: 160px;
  width: 160px;
  font-family: DINNextW01Regular, sans-serif;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 1em;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const ProductBoxLink = ({ id, label }: { id: string; label: string }) => <ProductBox data-id={id}>{label}</ProductBox>

interface ProductNavigationProps {
  moveCamera: (scene: SceneAndHotspot) => void
}
export const ProductNavigation = ({ moveCamera }: ProductNavigationProps): JSX.Element | null => {
  const { state, actions } = useAppState()
  const { width } = useWindowSize()
  const [boxId, setBoxId] = useState('')
  const [dragging, setDragging] = useState(false)
  const [x, setX] = useState(0)
  if (!state.productsOverlay) {
    actions.setDebugMessage('')
    return null
  }
  const handleStart = (e: DraggableEvent, d: DraggableData) => {
    const boxId = (e.target as any).attributes['data-id']?.value
    setBoxId(boxId)
    setX((d as any).x)
    console.log(`handleStart ${boxId} ${d.x}`)
    actions.setDebugMessage(`handleStart ${boxId} ${d.x}`)
  }
  const handleDrag = (_e: DraggableEvent, d: DraggableData) => {
    const newX = (d as any).x
    if (newX !== x) {
      setDragging(true)
    }
    actions.setDebugMessage(`handleDrag ${d.x}`)
  }
  const handleStop = (_e: DraggableEvent, d: DraggableData) => {
    const newX = (d as any).x
    console.log(`handleStop ${dragging} ${boxId} ${d.x}`)
    actions.setDebugMessage(`handleStop ${dragging} ${boxId} ${d.x}`)
    if (!dragging && boxId && newX === x) {
      actions.setProductsOverlay(false)
      actions.openPopup(boxId)
      moveCamera({ scene: Scene.Exhibition, hotspot: boxId })
    }
    setX(newX)
    setDragging(false)
    setBoxId('')
  }

  // if boxes do not fit into browser window width, allow dragging to the left for the
  // amount of pixels (of the boxes) that is currently hidden
  const boxWidth = 168 // box width 160px + grid gap 8px
  const dragMargin = boxWidth / 2 // space allowed to drag [before first / after last] box
  const boxesWidth = 8 * boxWidth + dragMargin
  const boundLeft = width < boxesWidth ? width - boxesWidth - 8 : 0

  return (
    <Container>
      <Draggable
        axis='x'
        onStart={handleStart}
        onDrag={handleDrag}
        onStop={handleStop}
        bounds={{ left: boundLeft, right: dragMargin }}
        defaultPosition={{ x, y: 0 }}
      >
        <ProductScroller>
          <ProductBoxLink id={Hotspot.ExhibitionSako100} label='Sako 100' />
          <ProductBoxLink id={Hotspot.ExhibitionSako90} label='Sako 90' />
          <ProductBoxLink id={Hotspot.ExhibitionS20} label='Sako S20' />
          <ProductBoxLink id={Hotspot.ExhibitionCartridges} label='Sako Cartridges' />
          <ProductBoxLink id={Hotspot.ExhibitionSako85} label='Sako 85' />
          <ProductBoxLink id={Hotspot.ExhibitionHistoric} label='Legendary historical models' />
          <ProductBoxLink id={Hotspot.ExhibitionTRG} label='Sako TRG' />
          <ProductBoxLink id={Hotspot.ExhibitionTikka} label='Tikka' />
          <ProductBoxLink id={Hotspot.ExhibitionPremium} label='Sako premium concept' />
        </ProductScroller>
      </Draggable>
    </Container>
  )
}
